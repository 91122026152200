@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css');



/* safe area in ios and android handled */
body {
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
}

//@supports(padding: max(0px)) {
//  .post {
//    padding-left: max(12px, env(safe-area-inset-left));
//    padding-right: max(12px, env(safe-area-inset-right));
//  }
//}

//html {
//  --ion-safe-area-top: env(safe-area-inset-top);
//  --ion-safe-area-bottom: env(safe-area-inset-bottom);
//  --ion-safe-area-left: env(safe-area-inset-left);
//  --ion-safe-area-right: env(safe-area-inset-right);
//}

body {
  background-color: #f0f9ff;
  height: 100%;
  margin: 0;
}

html {
  height: 100%;
}

.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 600,
    'GRAD' 0,
    'opsz' 48
}

h1, h2, h3, h4, h5, h6, p, label, span, button, a, .btn {
  font-family: 'Outfit', sans-serif;
  color: #162231;
}

h1, h2, h4 {
  font-weight: 600;
}



.btn {
  border-radius: 48px;
  padding: 8px 28px;
}

.btn-primary {
  background-color: #005377;
  border-color: #005377;
  color: #ffffff;
}

.btn-success {
  background-color: #06A77D;
  border-color: #06A77D;
  color: #ffffff;
}

.btn-danger {
  background-color: #FE4A49;
  border-color: #FE4A49;
  color: #ffffff;
}

.btn-lg {
  font-weight: 500;
  padding: 12px 28px;
}

.text-primary {
  color: #005377 !important;
}

.text-link {
  color: #005377 !important;
  cursor: pointer;
  text-decoration: underline;
}

.bg-primary {
  background-color: #005377 !important;
}

hr {
  border: 3px solid #bae6fd;
  border-radius: 5px;
}


.course-num {
  height: 56px;
  width: 56px;
  border-radius: 100%;

  h2 {
    margin-bottom: 0;
    line-height: 56px;
    text-align: center;
  }

}

.card {
 border: none;
  border-radius: 12px;
  -webkit-box-shadow: 0px 6px 30px 1px rgba(8, 47, 73, .15);
  -moz-box-shadow: 0px 6px 30px 1px rgba(8, 47, 73, .15);
  box-shadow: 0px 6px 30px 1px rgba(8, 47, 73, .15);
  padding: 2rem;
}


.grid-1 {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr;
}

.grid-auto, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7 {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill);
}

.grid-span-2, .grid-span-3, .grid-span-5 {
  grid-column: initial;
}

.form-control, .form-select {
 border-radius: 8px;
  padding: 8px 16px;
}

label {
  font-weight: 500;
}

@media (max-width: 768px) {
  .m-mb-3 {
    margin-bottom: 1rem !important;
  }

  .m-w-100 {
    width: 100% !important;
  }

  .m-w-50 {
    width: 50% !important;
  }

  .m-gap-3 {
    gap: 1rem !important;
  }

  .m-d-none {
    display: none !important;
  }

  .m-d-block {
    display: block !important;
  }

  .grid-auto, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7 {
    grid-gap: 16px;
  }

  .m-p-2 {
    padding: 1rem .5rem !important;
  }

  .m-p-1 {
    padding: .25rem !important;
  }
}

@media (min-width: 1024px) {
  .grid-2 {
    grid-template-columns: 1fr 1fr;
    .grid-span-2 {
      grid-column: span 2;
    }
  }

  .grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
    .grid-span-2 {
      grid-column: span 2;
    }
  }

  .grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .grid-span-2 {
      grid-column: span 2;
    }

    .grid-span-3 {
      grid-column: span 3;
    }
  }

  .grid-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    .grid-span-2 {
      grid-column: span 2;
    }

    .grid-span-3 {
      grid-column: span 3;
    }

    .grid-span-5 {
      grid-column: span 5;
    }
  }
}

@media (min-width: 1200px) {
  .grid-6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    .grid-span-2 {
      grid-column: span 2;
    }
  }

  .grid-7 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    .grid-span-2 {
      grid-column: span 2;
    }
  }
}

.grid-gap-1 {
  grid-gap: 8px !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.btn-loading {
  position: relative;
  text-indent: -9999px;
}

.btn-loading.superpose *:not(.spinner-border) {
  visibility: hidden;
}

.btn-loading .spinner-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  margin: auto;
  color: #ffffff;
}

.cursor-pointer {
  cursor: pointer;
}

.table {
  background-color: #ffffff;

  th {
    background-color: #005377;
    color: #ffffff;
    padding: 12px;
    font-weight: 500;
  }

  td {
    vertical-align: middle;
  }
  --bs-table-striped-bg: #b5d7e6;
  margin-bottom: 0;
}

.rounded-corners {
  border-radius: 8px;
  overflow: hidden;
}
.dropdown-menu {
  border: none;
}

.error-snackbar {
  background-color: #FE4A49;
  padding: 1rem;
  color: #ffffff;
  border-radius: 12px;
  .mat-simple-snack-bar-content {
    color: #ffffff;
  }
}

.logout-snackbar {
  background-color: #005377;
  padding: 1rem;
  color: #ffffff;
  border-radius: 12px;
  .mat-simple-snack-bar-content {
    color: #ffffff;
  }
}

.drop-shadow {
  -webkit-box-shadow: 0px 6px 30px 1px rgba(8, 47, 73, .15);
  -moz-box-shadow: 0px 6px 30px 1px rgba(8, 47, 73, .15);
  box-shadow: 0px 6px 30px 1px rgba(8, 47, 73, .15);
}
